var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qrcodes-statistics-page"},[_c('v-card',[_c('v-tabs',{staticClass:"qrcodes-statistics-tabs",attrs:{"background-color":"primary","dark":"","icons-and-text":"","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_c('v-tab',{staticClass:"qrcodes-statistics-tab",attrs:{"href":"#tab-1"}},[_vm._v(" Total Scans "),_c('span',[_vm._v("3")])]),_c('v-tab',{staticClass:"qrcodes-statistics-tab",attrs:{"href":"#tab-2"}},[_vm._v(" Unique Users "),_c('span',[_vm._v("2")])]),_c('v-tab',{staticClass:"qrcodes-statistics-tab",attrs:{"href":"#tab-3"}},[_vm._v(" Locations/Languages "),_c('span',[_vm._v("66.7% Czechia")])]),_c('v-tab',{staticClass:"qrcodes-statistics-tab",attrs:{"href":"#tab-4"}},[_vm._v(" Devices "),_c('span',[_vm._v("100.0% Phone")])])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((4),function(i){return _c('v-tab-item',{key:i,attrs:{"value":'tab-' + i}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('bar-chart',{attrs:{"chart-data":_vm.datacollection,"options":{
                legend: {
                  display: false,
                },
                tooltips: {
                  cornerRadius: 3,
                },
                responsive: true,
                maintainAspectRatio: false,
              }}})],1)],1)],1)}),1)],1),_c('v-card',{staticClass:"mt-5"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Latest Scans ")])],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"items-per-page":5}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }