<template>
  <div class="qrcodes-statistics-page">
    <v-card>
      <v-tabs
        class="qrcodes-statistics-tabs"
        v-model="tab"
        background-color="primary"
        dark
        icons-and-text
        grow
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab class="qrcodes-statistics-tab" href="#tab-1">
          Total Scans
          <span>3</span>
        </v-tab>

        <v-tab class="qrcodes-statistics-tab" href="#tab-2">
          Unique Users
          <span>2</span>
        </v-tab>

        <v-tab class="qrcodes-statistics-tab" href="#tab-3">
          Locations/Languages
          <span>66.7% Czechia</span>
        </v-tab>

        <v-tab class="qrcodes-statistics-tab" href="#tab-4">
          Devices
          <span>100.0% Phone</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="i in 4" :key="i" :value="'tab-' + i">
          <v-card flat>
            <v-card-text>
              <bar-chart
                :chart-data="datacollection"
                :options="{
                  legend: {
                    display: false,
                  },
                  tooltips: {
                    cornerRadius: 3,
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                }"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-card class="mt-5">
      <v-toolbar flat>
        <v-toolbar-title> Latest Scans </v-toolbar-title>
      </v-toolbar>

      <v-divider></v-divider>

      <v-data-table :headers="headers" :items="desserts" :items-per-page="5" />
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import BarChart from "../../components/charts/BarChart";

export default {
  name: "QRCodeStatistics",

  components: { BarChart },

  data() {
    return {
      tab: null,

      datacollection: null,

      headers: [
        { text: "Time", value: "time" },
        { text: "City", value: "city" },
        { text: "Country", value: "country" },
        { text: "Browser", value: "browser" },
        { text: "Platform", value: "platform" },
      ],

      desserts: [
        {
          time: "17:06 - 17/02/2021",
          city: "Minsk",
          country: "Belarus",
          browser: "Apple Safari",
          platform: "iPhone",
        },
        {
          time: "13:27 - 15/02/2021",
          city: "Brno",
          country: "Czechia",
          browser: "Google Chrome",
          platform: "Android",
        },
        {
          time: "13:26 - 15/02/2021",
          city: "Brno",
          country: "Czechia",
          browser: "Google Chrome",
          platform: "Android",
        },
      ],
    };
  },

  mounted() {
    this.fillData();
  },

  methods: {
    fillData() {
      const labels = [...new Array(30)]
        .map((i, idx) => moment().startOf("day").subtract(idx, "days").format("DD/MM/YYYY"))
        .reverse();
      this.datacollection = {
        labels,
        datasets: [
          {
            label: "Total Scans",
            backgroundColor: "#f87979",
            data: [...new Array(30)].map(this.getRandomInt),
          },
        ],
      };
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
  },
};
</script>

<style lang="scss" scoped>
.qrcodes-statistics-tab {
  font-size: 14px;
  font-weight: normal;
  span {
    font-size: 24px;
  }
}
</style>